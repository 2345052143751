dmx.Component('form-repeat', {

  initialData: {
    canAdd: true,
    items: []
  },

  attributes: {
    
    items: {
      type: [Array, Number],
      default: 0
    },

    min: {
      type: Number,
      default: 0
    },

    max: {
      type: Number,
      default: Infinity
    },

    sortable: {
      type: Boolean,
      default: false
    },

    handle: {
      type: String,
      default: null
    },

    animation: {
      type: Number,
      default: 0
    }

  },

  methods: {

    add () {
      this._add();
    },

    remove (index) {
      this._remove(index);
    },

    move (oldIndex, newIndex) {
      this._mode(oldIndex, newIndex, true);
    },

    moveToStart (index) {
      this._move(index, 0, true);
    },

    moveToEnd (index) {
      this._move(index, this.children.length - 1, true);
    },

    moveBefore (index) {
      this._move(index, index - 1, true);
    },

    moveAfter (index) {
      this._move(index, index + 1, true);
    },

    duplicate (index) {
      this._duplicate(index);
    },

    reset () {
      this._render();
    }

  },

  render (node) {
    this._template = this._templateFromChildren(node);

    const inputs = this._template.querySelectorAll('input[name], select[name], textarea[name]');

    inputs.forEach((input) => {
      const firstBracket = input.name.indexOf('[');

      if (firstBracket > 0) {
        input.setAttribute('dmx-bind:name', this.name + '[{{$index}}][' + input.name.slice(0, firstBracket) + ']' + input.name.slice(firstBracket));
      } else {
        input.setAttribute('dmx-bind:name', this.name + '[{{$index}}][' + input.name + ']');
      }
    });

    if (this.props.sortable) {
      this._sortable = Sortable.create(node, {
        handle: this.props.handle,
        onEnd: event => this._move(event.oldIndex, event.newIndex)
      });
    }

    this._render();
    this._refresh();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('items')) {
      this._render();
    }

    if (updatedProps.has('sortable')) {
      if (this._sortable) {
        this._sortable.option('disabled', !this.props.sortable);
      } else {
        this._sortable = Sortable.create(node, {
          handle: this.props.handle,
          onEnd: event => this._move(event.oldIndex, event.newIndex)
        });
      }
    }

    if (updatedProps.has('handle')) {
      if (this._sortable) {
        this._sortable.option('handle', this.props.handle);
      }
    }

    if (updatedProps.has('animation')) {
      if (this._sortable) {
        this._sortable.option('animation', this.props.animation);
      }
    }

    this._refresh();
  },

  _render () {
    const items = dmx.repeatItems(typeof this.props.items == 'string' ? Number(this.props.items) : this.props.items);
    
    if (items.length < this.props.min) items.length = this.props.min;
    if (items.length > this.props.max) items.length = this.props.max;

    this._clear();

    for (let i = 0; i < items.length; i++) {
      this._add(items[i]);
    }
  },

  _duplicate (index) {
    this._add();
    
    this._move(this.children.length - 1, index + 1, true);

    let fromInputs = [];
    this.children[index].$nodes.forEach((node) => {
      if (node.nodeType == 1) {
        fromInputs.push(...Array.from(node.querySelectorAll('input[name], textarea[name], select[name]')));
      }
    });
    
    let toInputs = [];
    this.children[index + 1].$nodes.forEach((node) => {
      if (node.nodeType == 1) {
        toInputs.push(...Array.from(node.querySelectorAll('input[name], textarea[name], select[name]')));
      }
    });

    // Only do something when the inputs match
    if (fromInputs.length == toInputs.length) {
      for (let i = 0; i < fromInputs.length; i++) {
        const fromInput = fromInputs[i];
        const toInput = toInputs[i];

        // Inputs do not match???
        if (fromInput.tagName != toInput.tagName) break;

        if (fromInput.tagName == 'TEXTAREA') {
          // textarea just copy value
          toInput.value = fromInput.value;
        } else if (fromInput.tagName == 'INPUT') {
          if (fromInput.type == 'file' || fromInput.type == 'password') {
            // skip file and password types
            continue;
          }

          if (fromInput.type == 'checkbox' || fromInput.type == 'radio') {
            // checkbox and radio we have to copy the checked property
            toInput.checked = fromInput.checked;
          } else {
            // any other type input just copy value
            toInput.value = fromInput.value;
          }
        } else if (fromInput.tagName == 'SELECT') {
          if (fromInput.multiple) {
            // for multiple selection we need to loop over the options
            // first loop only the selected options
            for (const fromOption of fromInput.selectedOptions) {
              // second loop on target to find matching value
              for (const toOption of toInput.options) {
                if (fromOption.value == toOption.value) {
                  // set selected when value matches
                  toOption.selected = true;
                }
              }
            }
          } else {
            // for single selection we can simply copy the value
            toInput.value = fromInput.value;
          }
        }

        // trigger a change event on the target to let App Connect know it changed
        toInput.dispatchEvent(new Event('change'));
      }
    }
  },

  _add (data = {}) {
    if (this.children.length >= this.props.max) return;

    const RepeatItem = dmx.Component('repeat-item');
    const child = new RepeatItem(this._template.cloneNode(true), this, data);

    child.$nodes.forEach((node) => {
      this.$node.appendChild(node);
      child.$parse(node);
    });

    this.children.push(child);

    this._refresh();
  },

  _remove (index) {
    if (this.children.length <= this.props.min) return;

    this.children.splice(index, 1).forEach((child) => {
      child.$destroy();
    });

    this._refresh();
  },

  _refresh () {
    this.children.forEach((child, index) => {
      child.set({
        $index: index,
        $canRemove: this.children.length > this.props.min,
        $canMoveToStart: index > 0,
        $canMoveBefore: index > 0,
        $canMoveAfter: index < this.children.length - 1,
        $canMoveToEnd: index < this.children.length - 1
      });
    });

    this.set('canAdd', this.children.length < this.props.max);
    this.set('items', this.children.map(child => child.data));
  },

  _clear () {
    this.children.splice(0).forEach((child) => {
      child.$destroy();
    });

    this.$node.innerHTML = '';
  },

  _move (oldIndex, newIndex, updateDOM) {
    if (oldIndex < 0 || oldIndex >= this.children.length) return;
    if (newIndex < 0 || newIndex >= this.children.length) return;

    this.children.splice(newIndex, 0, this.children.splice(oldIndex, 1)[0]);

    if (updateDOM) {
      this.$node.innerHTML = '';
      this.children.forEach(child => {
        child.$nodes.forEach((node) => {
          this.$node.appendChild(node);
        });
      })
    }
    
    this._refresh();
  },

  _templateFromChildren (node) {
    const template = document.createDocumentFragment();
    
    while (node.hasChildNodes()) {
      template.appendChild(node.firstChild);
    }

    return template;
  }

});